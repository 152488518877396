import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { EXPCOLUMNS } from "src/columns";
import SortingTable from "src/modules/components/common/Table/SortingTable";
import Button from "src/modules/components/common/Button/Button";
import { MinifichaContext } from "src/modules/contexts/MinifichaContextProvider";
import {
  getMinifichasByExp,
  getNewMinifichaCode,
  saveTree,
} from "src/modules/actions/minifichaActions";
import Graph from "src/modules/components/common/Graph/Graph";
import { generateMermaidTree } from "src/services/generateTree";
const Fichas = () => {
  let { expId } = useParams();
  const { state, dispatch } = useContext(MinifichaContext);
  const navigate = useNavigate();
  const [dossiers, setDossiers] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const tree = await generateMermaidTree(expId);
      saveTree(expId, tree, dispatch);
      await getMinifichasByExp(expId, dispatch);
      await getNewMinifichaCode(dispatch);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (state.minifichas.length === 0) return;
    const dossiers = state.minifichas.map((dossier) => {
      return {
        name: dossier.header.name,
        code: dossier.header.code,
        link: `/editficha/${dossier.file.expId}/${dossier.header.code}`,
      };
    });
    setDossiers(dossiers);
  }, [state.minifichas]);

  const navigateToEdit = async () => {
    navigate(`/editficha/${expId}/${state.newMinifichaCode}`);
  };

  const divStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  if (!state.tree && dossiers?.length > 0) {
    return (
      <h1
        style={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
        }}>
        Cargando datos
      </h1>
    );
  }

  return (
    <div style={divStyle}>
      <div style={{    marginBottom: "10px", display: "flex", width: "75%", justifyContent: "space-between"}}>
        <Button
          content="<<"
          onClick={() => navigate("/expedientes")}
        />
        <Button
          content="+"
          onClick={navigateToEdit}
        />
      </div>
      {dossiers?.length > 0 ? (
        <SortingTable
          columnsData={EXPCOLUMNS}
          tbodyData={dossiers}
        />
      ) : (
        <h1
          style={{
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
          }}>
          No hay Minifichas para este expediente
        </h1>
      )}

      {state.tree && <Graph chart={state.tree} />}
    </div>
  );
};

export default Fichas;
