import minifichaActionTypes from "../actionTypes/minifichaActionTypes";
import { Dossier } from "src/lib/api";

export const getNewMinifichaCode = async(dispatch) => {
  try {
    const minifichas = await Dossier.find({"header.filetype": "minificha"})
    const length = minifichas.length + 1;
    const minCifras = Math.max(4, String(length).length);
    const numeroFormateado = String(length).padStart(minCifras, "0");
    dispatch({type: minifichaActionTypes.SET_NEW_MINIFICHA_CODE, payload: `G-${numeroFormateado}`})
  } catch (error) {
    dispatch({ type: minifichaActionTypes.FETCH_FAILURE, payload: error.message });
  }
}

export const getMinifichasByExp = async(expId, dispatch) => {
  try {
    const minifichas = await Dossier.find({"header.filetype": "minificha", "file.expId": expId})
    const personaOptions = minifichas.map((m) => {
      return { label: m.header.name, value: m.header.code };
    })
    dispatch({type: minifichaActionTypes.FETCH_FIND_MINIFICHAS_SUCCESS, payload: {minifichas, personaOptions}})
  } catch (error) {
    dispatch({ type: minifichaActionTypes.FETCH_FAILURE, payload: error.message });
  }
}

export const readMinificha = async(code, dispatch) => {
  try {
    let minificha = await Dossier.read(code)
    if(!minificha) {
      minificha = {
        header: {
          code
        },
        file:{
          relaciones: [],
          direcciones: [],
          telefonos: [],
          emails: [],
          otros: []
        }
      }
    }
    dispatch({type: minifichaActionTypes.FETCH_MINIFICHA_SUCCESS, payload: minificha})
  } catch (error) {
    dispatch({ type: minifichaActionTypes.FETCH_FAILURE, payload: error.message });
  }
}

export const addToArray = (key, value, dispatch) => {
  dispatch({type: minifichaActionTypes.ADD_TO_ARRAY_MINIFICHA, payload: {key, value}})
}

export const removeFromArray = (name, dispatch) => {
  const [key, index] = name.split('-')
  dispatch({type: minifichaActionTypes.DELETE_FROM_ARRAY_MINIFICHA, payload: {key, index: Number(index)}})
}

export const editFile = (name, value, dispatch) => {
  dispatch({type: minifichaActionTypes.EDIT_FILE_MINIFICHA, payload: {name, value}})
}

export const saveTree = async(expId, tree, dispatch) => {
  dispatch({type: minifichaActionTypes.SAVE_TREE, payload: tree})
  await Dossier.modify(expId, [{action: 'set', pointer:'file.arbolGenealogico', value: tree}])
}