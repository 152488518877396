import React from 'react'
import Login from 'src/routes/Login/Login';
import Editar from 'src/routes/Editar/Editar';
import Inicio from './routes/Inicio/Inicio';
import Fichas from 'src/routes/Fichas/Fichas';
const PagesData = [
  {
    id: 'inicio',
    title: 'Expedientes',
    path: '/expedientes',
    roles: ['superadmin', 'hyperuser', 'admin', 'user', 'developer'],
    component: <Inicio />,
    showOnSidebar: true,
  },
  {
    id: 'fichas',
    title: 'Minifichas',
    path: '/minifichas/:expId',
    roles: ['superadmin', 'hyperuser', 'admin', 'user', 'developer'],
    component: <Fichas />,
    showOnSidebar: false,
  },
  {
    id: 'editar',
    title: 'Editar',
    path: '/editficha/:expId/:minifichaId',
    roles: ['superadmin', 'hyperuser', 'admin', 'user', 'developer'],
    component: <Editar />,
    showOnSidebar: false,
  },
  {
    id: 'login',
    title: 'Sesión',
    path: '/',
    roles: [null],
    component: <Login />,
    showOnSidebar: true,
  },
  {
    id: 'cerrar-sesion',
    title: 'Cerrar Sesión',
    roles: ['superadmin', 'hyperuser', 'admin', 'user', 'developer'],
    onClick: () => {
      sessionStorage.clear()
      window.location.href = '/';
    },
    showOnSidebar: true
  },
];
export default PagesData;
