import React, { useEffect, useState, useRef } from "react";
import "./Navbar.css";
import PagesData from "src/PagesData";
import Title from "src/modules/components/common/Title/Title";
import NavBarOption from "./NavBarOption";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
const NavBar = () => {
  const { role } = useAppContext();
  const [title, setTitle] = useState("");
  const navRef = useRef();
  const showNavBar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  useEffect(() => {
    const paths = window.location.pathname.split("/");
    let path = ''
    if (paths[1] == "minifichas") {
      path = "/minifichas/:expId";
    }
    else if(paths[1] == 'editficha'){
      path = '/editficha/:expId/:minifichaId'
    }
    else if(paths[1] == ''){ 
      path = '/'
    }
    else {
      path = window.location.pathname
    }
    const page = PagesData.find((val) => val.path === path);
    setTitle(page?.title ?? '');
  }, [window.location.pathname]);
  return (
    <header>
      <i className="fas fa-bars nav-btn" onClick={showNavBar} />
      <Title text={"GALA"} />
      <Title text={title} />
      <nav ref={navRef}>
        {PagesData.map((val) => {
          if (
            role !== undefined &&
            val.roles.includes(role) &&
            val.showOnSidebar
          ) {
            return (
              <NavBarOption
                val={val}
                key={val.id}
                setTitle={setTitle}
              />
            );
          }
        })}
        <i className="fas fa-times nav-close-btn" onClick={showNavBar} />
      </nav>
    </header>
  );
};
export default NavBar;
