import React, { useEffect, useState } from "react";
import Button from "src/modules/components/common/Button/Button";
import "./Relation.css";
import Select from "src/modules/components/common/Select/Select";
import TextArea from "../../common/Text Area/TextArea";
import Input from "../../common/Input/Input";
import { useParams } from "react-router";
import { Dossier } from "src/lib/api";
import Collapsible from "../../common/Collapsible/Collapsible";
const Relation = ({ name, relation, onDelete, onChange }) => {
  const { expId, minifichaId } = useParams();

  const buttonStyle = {
    minWidth: "100px",
    alignSelf: "flex-end",
  };

  const handleDelete = () => {
    onDelete(name);
  };

  const handleChange = (name) => (value) => {
    switch (name) {
      case "name": {
        if (!value) {
          relation.persona = "";
          return;
        }
        const updatedRelation = { ...relation };
        updatedRelation.person = value;
        onChange(updatedRelation);
        break;
      }
      case "type": {
        if (!value) {
          relation.type = "";
          relation.relation = "";
          return;
        }
        const updatedRelation = { ...relation };
        updatedRelation.relation = value.value;
        updatedRelation.type = value.data;
        onChange(updatedRelation);
        break;
      }
    }
  };

  const onChangeThis = (e) => {
    if (e.target.name === "heredero") {
      const updatedRelation = { ...relation };
      updatedRelation[e.target.name] = e.target.checked;
      onChange(updatedRelation);
      return;
    }
    const updatedRelation = { ...relation };
    updatedRelation[e.target.name] = e.target.value;
    onChange(updatedRelation);
  };

  const [personaOptions, setPersonaOptions] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await Dossier.list("652fbda51e4dc38c5b5f5099", 0, 100000000);
      const filtered = data.filter(
        (dossier) =>
          dossier.file.expId === expId &&
          dossier.header.code !== minifichaId
      );
      setPersonaOptions(
        filtered.map((filteredData) => {
          return {
            label: filteredData.header.name,
            value: filteredData.header.code,
          };
        })
      );
    };
    fetchData();
  }, [expId, minifichaId]);

  const tipoOptions = [
    { label: "Cohabitante", value: "cohabitante", data: "general" },
    { label: "Vecino/a", value: "vecino", data: "general" },
    { label: "Conocido/a", value: "conocido", data: "general" },
    { label: "Padre/Madre", value: "padre", data: "familiar" },
    { label: "Hijo/a", value: "hijo", data: "familiar" },
    { label: "Hermano/a", value: "hermano", data: "familiar" },
    { label: "Tío/a", value: "tio", data: "familiar" },
    { label: "Sobrino/a", value: "sobrino", data: "familiar" },
    { label: "Primo/a", value: "primo", data: "familiar" },
    { label: "Cónyuge", value: "conyuge", data: "familiar" },
  ];

  return (
    <Collapsible
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
            {relation.person  &&
            <h3 style={{ margin: 0, padding: 0 }}>
              {relation.person.label} {}
              {relation.relation ? ` - ${tipoOptions.find((tipo) => tipo.value === relation.relation).label}` : ''}
            </h3>
            }
          <Button
            onClick={handleDelete}
            content="Eliminar"
            buttonStyle={buttonStyle}
          />
        </div>
      }
      contentStyle={{display: "flex", flexDirection: "column", gap: '10px'}}
      >
      <Select
        isSearchable={true}
        isClearable={true}
        options={personaOptions}
        placeholder="Persona"
        onChange={handleChange("name")}
        label="Persona"
        value={relation.person}
        activeLabel={true}
        style={{ width: "200px" }}
      />
      <Select
        isSearchable={true}
        isClearable={true}
        options={tipoOptions}
        placeholder="Tipo"
        onChange={handleChange("type")}
        label="Tipo"
        activeLabel={true}
        value={tipoOptions.find((tipo) => tipo.value === relation.relation)}
        style={{ width: "200px" }}
      />
      <Input
        type="checkbox"
        name="heredero"
        onChange={onChangeThis}
        checked={relation.heredero}
        label="Heredero"
        activeLabel={true}
      />
      <TextArea
        id="notas"
        value={relation.notas}
        name="notas"
        placeholder="Notas"
        onChange={onChangeThis}
        label="Notas"
        activeLabel={true}
      />
    </Collapsible>
  );
};

export default Relation;
