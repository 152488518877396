// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 20px;
  font-family: Ping LCG Regular;
  
}

th, td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  text-align: left;
}

td {
  color: #333;
}

th {
  background-color: #333;
  color: white;
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:nth-child(odd) {
  background-color: white;
}

tr:hover {
  background-color: #ddd;
}


@media(max-width:850px) {
  th {
    display: none;
  }
  td {
    display: block;
    padding: 0.5rem 1rem;
    font-weight: normal;
    border: 0;
  }
  td::before {
    content: attr(data-cell) ": ";
    font-weight: 700;
    text-transform: capitalize;
  }
  td:first-child {
    background-color: #333;
    color: white;
  }

  table {
    border-collapse: separate; 
    border-spacing: 0 1em;
    margin-top: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/modules/components/common/Table/Table.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,6BAA6B;;AAE/B;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;;AAGA;EACE;IACE,aAAa;EACf;EACA;IACE,cAAc;IACd,oBAAoB;IACpB,mBAAmB;IACnB,SAAS;EACX;EACA;IACE,6BAA6B;IAC7B,gBAAgB;IAChB,0BAA0B;EAC5B;EACA;IACE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,aAAa;EACf;AACF","sourcesContent":["table {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  margin-bottom: 20px;\r\n  margin-top: 20px;\r\n  font-family: Ping LCG Regular;\r\n  \r\n}\r\n\r\nth, td {\r\n  padding: 12px;\r\n  border-bottom: 1px solid #ddd;\r\n  font-weight: bold;\r\n  text-align: left;\r\n}\r\n\r\ntd {\r\n  color: #333;\r\n}\r\n\r\nth {\r\n  background-color: #333;\r\n  color: white;\r\n  cursor: pointer;\r\n}\r\n\r\ntr:nth-child(even) {\r\n  background-color: #f2f2f2;\r\n}\r\n\r\ntr:nth-child(odd) {\r\n  background-color: white;\r\n}\r\n\r\ntr:hover {\r\n  background-color: #ddd;\r\n}\r\n\r\n\r\n@media(max-width:850px) {\r\n  th {\r\n    display: none;\r\n  }\r\n  td {\r\n    display: block;\r\n    padding: 0.5rem 1rem;\r\n    font-weight: normal;\r\n    border: 0;\r\n  }\r\n  td::before {\r\n    content: attr(data-cell) \": \";\r\n    font-weight: 700;\r\n    text-transform: capitalize;\r\n  }\r\n  td:first-child {\r\n    background-color: #333;\r\n    color: white;\r\n  }\r\n\r\n  table {\r\n    border-collapse: separate; \r\n    border-spacing: 0 1em;\r\n    margin-top: 0;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
