import React, { useEffect } from "react";
import { default as ReactSelect } from "react-select";
import "../Input/Input.css";
//TODO: EL SELECT UTILIZA EL CSS DE INPUT, HAY QUE CREAR UN CSS PARA SELECT
const Select = ({
  isMulti,
  isClearable,
  isSearchable,
  isDisabled,
  onChange,
  name,
  options,
  placeholder,
  value,
  errorMessage,
  requiredPassed,
  setRequiredPassed,
  required,
  ...props
}) => {
  useEffect(() => {
    //Establece null como valor inicial
    if (required === true && setRequiredPassed) {
      if (requiredPassed[name] === undefined) {
        setRequiredPassed((prevState) => ({
          ...prevState,
          [name]: null,
        }));
      }
    }
  }, []);
  const handleSelectIsNotEmpty = (e) => {
    if (e === null) {
      setRequiredPassed(prevState => ({
        ...prevState,
        [name]: false,
      }));
    } else {
      setRequiredPassed(prevState=>({
        ...prevState,
        [name]: true,
      }));
    }
  };

  const styles = {
    container: (base, state) => ({
      ...base,
      flex: "1 1 343px",
      "@media (max-width: 768px)": {
        flex: "none",
        width: "-webkit-fill-available",
      },
    }),
    control: (base, state) => ({
      ...base,
      "--placeholderTextColor": "rgba(255, 255, 255, 0.7)",
      alignSelf: "center",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      borderWidth: state.isFocused ? "2px" : "0.5px",
      color: "rgb(255, 255, 255)",
      height: "44px",
      paddingLeft: "12px",
      width: "100%",
      fontFamily: "Ping LCG Regular",
      fontWeight: "400",
      fontStyle: "normal",
      fontSize: "16px",
      letterSpacing: "0px",
      borderColor: state.isFocused
        ? "rgb(0, 0, 0)"
        : "rgba(255, 255, 255, 0.4)",
      boxShadow: state.isFocused
        ? "0px 0px 0px 1px rgb(255, 255, 255)"
        : "none",
      pointerEvents: 'all',

      "&:hover": {
        borderColor: state.isFocused
          ? "rgb(0, 0, 0)"
          : "rgba(255, 255, 255, 0.4)",
        cursor: state.isDisabled? 'not-allowed': "pointer",
      },
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "rgba(0, 0, 0, 0.2)" : null,
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
      },
    }),
    menu: (base, state) => ({
      ...base,
      "--placeholderTextColor": "rgba(255, 255, 255, 0.7)",
      alignSelf: "center",
      backgroundColor: "rgb(105, 105, 105)",
      borderRadius: "4px",
      borderWidth: "0.5px",
      color: "rgb(255, 255, 255)",
      fontFamily: "Ping LCG Regular",
      fontWeight: "400",
      fontStyle: "normal",
      fontSize: "14px",
      letterSpacing: "0px",
      borderColor: "rgba(255, 255, 255, 0.4)",
    }),
    input: (base, state) => ({
      ...base,
      color: "rgb(255, 255, 255)",
      fontFamily: "Ping LCG Regular",
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: "0px",
      pointerEvents: 'all',
      "&:hover": {
        cursor: state.isDisabled? 'not-allowed': "text",
      },
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      pointerEvents: 'all',

      "&:hover": {
        cursor: state.isDisabled? 'not-allowed':"pointer",
      },
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "rgb(255, 255, 255)",
    }),
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      color: "white",
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      "&:hover": {
        color: "white",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        cursor: "pointer",
      },
    }),
  };
  return (
    <div>
      <div className="input-container">
        {props.label  && (
          <label className="input-label">{props.label || "texto"}</label>
        )}
        <ReactSelect
          menuPortalTarget={document.body}
          isClearable={isClearable || true}
          isSearchable={isSearchable || true}
          name={name}
          options={options}
          onChange={(e) => {
            onChange(e);
            if (requiredPassed && setRequiredPassed) handleSelectIsNotEmpty(e);
          }}
          placeholder={placeholder || "Select"}
          value={value}
          styles={styles}
          isMulti={isMulti || false}
          isDisabled={isDisabled}
        />
      </div>
      {requiredPassed && requiredPassed[name] === false && (
        <span className="input-required">{errorMessage}</span>
      )}
    </div>
  );
};

export default Select;
