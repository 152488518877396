import React, { useMemo, useState } from 'react';
import './Table.css'
import Input from '../../common/Input/Input';

const SortingTable =({columnsData, tbodyData, canFilter}) => {
  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tbodyData, [tbodyData]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [searchText, setSearchText] = useState('');

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const removeAccents = (str) => {
    return str
      .normalize("NFD") // Normaliza la cadena descompuesta en caracteres básicos + diacríticos
      .replace(/[\u0300-\u0302\u0308\u030B\u0327]/g, ""); // Elimina los diacríticos excepto la "ñ"
  };

  const sortedData = () => {
    let filteredData = data;
  
    if (sortColumn) {
      filteredData = filteredData.sort((a, b) => {
        const valueA = getNestedValue(a, sortColumn);
        const valueB = getNestedValue(b, sortColumn);
  
        if (typeof valueA === "number" && typeof valueB === "number") {
          return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
        } else {
          return sortOrder === "asc"
            ? removeAccents(String(valueA)).localeCompare(
                removeAccents(String(valueB))
              )
            : removeAccents(String(valueB)).localeCompare(
                removeAccents(String(valueA))
              );
        }
      });
    }
  
    // Filtrar los datos según el texto de búsqueda
    if (searchText.trim() !== "") {
      filteredData = filteredData.filter((item) => {
        for (const column of columns) {
          const cellValue = String(getNestedValue(item, column.accessor));
          if (
            removeAccents(cellValue)
              .toLowerCase()
              .includes(removeAccents(searchText).toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }
  
    return filteredData;
  };
  
  

  // Función para obtener el valor de una columna anidada
  const getNestedValue = (obj, path) => {
    const keys = path.split('.');
    return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : ''), obj);
  };
  const renderSortArrow = (column) => {
    if (column === sortColumn) {
      return sortOrder === 'asc' ? '↑' : '↓';
    }
    return null;
  };

  return (
    <div style={{ margin: '0 auto', width: '75%' }}>
      <Input
        onChange={handleSearchChange}
        value={searchText}
        placeholder='Buscar...'
        label='Busqueda'
        name="search"
        type="text"
        activeLabel={true}
      />
      <table>
        <thead>
          <tr>
            {
              columns.map((column, index) => 
              <th key={index} onClick={() => handleSort(column.accessor)}>
                {column.Header} {renderSortArrow(column.accessor)}
              </th>)
            }
          </tr>
        </thead>
        <tbody>
          {sortedData().map((data, index) => 
              <tr key={index}>
                {
                  columns.map((column, index) => 
                    <td key={index} data-cell={column.Header}>
                      { column.type === 'link' ? 
                        <a href={data.link}>{data[column.accessor]}</a> :
                        <>{data[column.accessor]}</>
                      }
                    </td>
                  )
                }
              </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SortingTable;
