const minifichaActionTypes = {
  SET_NEW_MINIFICHA_CODE: "SET_NEW_MINIFICHA_CODE",
  FETCH_FAILURE: "FETCH_FIND_MINIFICHAS_FAILURE",
  FETCH_FIND_MINIFICHAS_SUCCESS: "FETCH_FIND_MINIFICHAS_SUCCESS",
  FETCH_MINIFICHA_SUCCESS: "FETCH_MINIFICHA_SUCCESS",
  ADD_TO_ARRAY_MINIFICHA: "ADD_TO_ARRAY_MINIFICHA",
  DELETE_FROM_ARRAY_MINIFICHA: "DELETE_FROM_ARRAY_MINIFICHA",
  EDIT_FILE_MINIFICHA: "EDIT_FILE_MINIFICHA",
  SAVE_TREE: "SAVE_TREE",
};

export default minifichaActionTypes;