import "./App.css";
import NavBar from "src/modules/components/header/NavBar/NavBar";
import PagesData from "./PagesData";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import React from "react";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import RequireAuth from "src/modules/components/header/RequireAuth/RequireAuth";
import MinifichaContextProviderRouter from "./MinifichaContextProviderRouter";

function App() {
  const { role } = useAppContext();

  function createRouteByRol(pageId) {
    const page = PagesData.find((page) => page.id === pageId);

    if (!page) {
      console.log(`Página no encontrada para pageId: ${pageId}`);
      return null;
    }

    const roleFound = page.roles.includes(role);

    return (
      <Route
        key={pageId}
        path={page.path}
        element={
          <RequireAuth isLogged={roleFound}>{page.component}</RequireAuth>
        }
      />
    );
  }

  return (
    <div>
      <BrowserRouter>
        <div className="css-175oi2r page-container">
          <NavBar />
          <Routes>
            {createRouteByRol("inicio")}
            {createRouteByRol("login")}
            {createRouteByRol("cerrar-sesion")}
            <Route element={<MinifichaContextProviderRouter/>}>
              {createRouteByRol("fichas")}
              {createRouteByRol("editar")}
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
