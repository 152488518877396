import React, { useContext } from "react";
import { MinifichaContext } from "src/modules/contexts/MinifichaContextProvider";
import Input from "../common/Input/Input";
import Button from "../common/Button/Button";
import Collapsible from "../common/Collapsible/Collapsible";

const EditList = ({ title, name, placeHolder, onChange, onAdd, onDelete }) => {
  const buttonStyle = {
    minHeight: "44px",
    minWidth: "144px",
  };

  const { state } = useContext(MinifichaContext);
  return (
    <Collapsible
      header={
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <h3>{title}: {state.minificha?.file?.[name]?.length}</h3>
          <Button
            name={`add-${name}`}
            onClick={onAdd}
            content="+"
            buttonStyle={buttonStyle}
          />
        </div>
      }
      >
      <div className="contacto-container">
        {state.minificha?.file[name]?.map((otro, index) => (
          <div className="contacto-line">
            <Input
              name={`${name}-${index}`}
              type="text"
              placeholder={placeHolder}
              onChange={onChange}
              value={otro}
              key={index}
            />
            <Button
              onClick={() => onDelete(`${name}-${index}`)}
              content="Eliminar"
              buttonStyle={buttonStyle}
            />
          </div>
        ))}
      </div>
    </Collapsible>
  );
};

export default EditList;
