import React, { useEffect, useState } from "react";
import "./Login.css";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import { useNavigate } from "react-router";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Auth, User } from "src/lib/api";

const Login = () => {
  const {
    userSession,
    setUserSession,
  } = useAppContext();
  const navigate = useNavigate();
  const [emailLogin, setEmailLogin] = useState("");
  const [password, setPassword] = useState("");
  const buttonStyle = {
    minHeight: "44px",
    minWidth: "144px",
  };
  useEffect(() => {
    console.log('login mounted')
    setEmailLogin("");
    setPassword("");
  }, []);

  function handleChange(name, value) {
    if (name === "email") {
      setEmailLogin(value);
    } else {
      setPassword(value);
    }
  }

  const login = async (event) => {
    event.preventDefault();
    if (!emailLogin || !password) {
      return alert("Por favor, rellene todos los campos");
    }

    const response = await Auth.login(emailLogin, password);

    if (!response?.token)
      return alert("Usuario o contraseña incorrectos");

    setUserSession({
      ...userSession,
      token: response?.token,
      role: "guest",
    });

    const userData = await User.get();

    setUserSession({
      ...userSession,
      token: response?.token,
      role: userData.role || "guest",
    });

    // setName(userData.name || "");
    setEmailLogin(userData.email || "");

    navigate("/expedientes");
  };

  return (
    <div>
      <div className="content-container">
        <form className="login-container">
          <p>¿Tienes una cuenta?</p>
          <div className="css-175oi2r">
            <Input
              name="email"
              type="text"
              placeholder="Email"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={emailLogin}
            />
            <Input
              name="password"
              type="password"
              placeholder="Password"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              value={password}
            />
          </div>
          <div className="css-175oi2c" style={{ alignItems: "center" }}>
            <Button
              content="Iniciar Sesión"
              disabled={emailLogin && password ? false : true}
              buttonStyle={buttonStyle}
              onClick={login}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;
