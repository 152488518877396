import { Outlet } from "react-router-dom";
import { MinifichaContextProvider } from "./modules/contexts/MinifichaContextProvider";

const MinifichaContextProviderRouter = () => {
  return (
    <MinifichaContextProvider>
      <Outlet />
    </MinifichaContextProvider>
  );
};

export default MinifichaContextProviderRouter;
