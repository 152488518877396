import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import { minifichaReducer } from "../reducers/minifichaReducer";

const initialState = {
  newMinifichaCode: "",
  minifichas: [],
  minificha: null,
  error: null,
};

export const MinifichaContext = createContext(initialState);

export const MinifichaContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(minifichaReducer, initialState);

  return (
    <MinifichaContext.Provider value={{ state, dispatch }}>
      {children}
    </MinifichaContext.Provider>
  );
};

MinifichaContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
