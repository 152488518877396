import React, { createContext, useContext, useState, useEffect } from 'react';
import { useCookie } from "src/modules/hooks/useCookie";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [userSession, setUserSession] = useCookie("user_session", {
    role: "guest",
    token: null,
  });
  const [setupInfo, setSetupInfo] = useState({})
 
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppContext.Provider value={{ userSession, setUserSession, setupInfo, setSetupInfo }}>
      {children}
    </AppContext.Provider>
  );
};
export const useAppContext = () => useContext(AppContext);
