import React, {  useEffect, useState } from 'react'
import './Inicio.css'
import { EXPCOLUMNS } from 'src/columns'
import SortingTable from 'src/modules/components/common/Table/SortingTable'
import { Dossier, View } from 'src/lib/api';
const Inicio = () => {
  useEffect(() => {
    const fetchData = async() => {
      const view = await View.read('dgalaexp')
      const frameId = view.frames[0]._id
      const data = await Dossier.list(frameId, 0, 10000000)
      if(data.error) return
        const dossiers = data.map(dossier => {
            return {name:dossier.header.name, code: dossier.header.code, link: `/minifichas/${dossier.header.code}`} 
        })
        setDossiers(dossiers);    }
    fetchData()    
    }, [])
    const [dossiers, setDossiers] = useState()
    
    return (
      <div>
        { dossiers?.length > 0 ? <SortingTable columnsData={EXPCOLUMNS} tbodyData={dossiers}/> : <h1 style={{display:'flex', textAlign: 'center', flexDirection: 'column'}}>Buscando Expedientes...</h1>}
      </div>
  )
}

export default Inicio