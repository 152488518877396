import React, { useEffect, useState, useRef, useContext } from "react";
import "./Editar.css";
import Input from "src/modules/components/common/Input/Input";
import Title from "src/modules/components/common/Title/Title";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Button from "src/modules/components/common/Button/Button";
import Relation from "src/modules/components/Editar/Relation/Relation";
import Select from "src/modules/components/common/Select/Select";
import Direction from "src/modules/components/Editar/Direction/Direction";
import { useNavigate, useParams } from "react-router-dom";
import { getProvincias } from "src/services/locations";
import { Dossier } from "src/lib/api";
import { generateMermaidTree } from "src/services/generateTree";
import { MinifichaContext } from "src/modules/contexts/MinifichaContextProvider";
import {
  addToArray,
  editFile,
  getMinifichasByExp,
  readMinificha,
  removeFromArray,
  saveTree,
} from "src/modules/actions/minifichaActions";
import EditList from "src/modules/components/Editar/EditList";

const newData = {
  direcciones: {
    provincia: "",
    municipio: "",
    pais: "España",
    fechas: [],
  },
  relaciones: {
    type: "",
    person: null,
  },
  telefonos: "",
  emails: "",
  otros: "",
};
const Editar = (props) => {
  const { expId, minifichaId } = useParams();
  const { state, dispatch } = useContext(MinifichaContext);
  const { minificha } = state;
  const buttonStyle = props.buttonStyle || {
    minHeight: "44px",
    minWidth: "144px",
  };

  const [provincias, setProvincias] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      if (state.minifichas.length === 0)
        await getMinifichasByExp(expId, dispatch);
    };
    setProvincias(
      getProvincias().map((provincia) => {
        return { label: provincia.ux, value: provincia.ux };
      })
    );
    fetchData();
  }, []);

  const save = async () => {
    const dossier = {
      ...minificha,
      header: {
        name: `${minificha?.file?.apellido1 || ''} ${minificha?.file?.apellido2 || ''}, ${minificha?.file?.nombre}`,
        code: minifichaId,
        clients: [],
        owner: "",
        status: "open",
        filetype: "minificha",
      },
      file:{ ...minificha.file, expId},
    };
    await Dossier.crupdate(dossier);
    const tree = await generateMermaidTree(expId);
    saveTree(expId, tree, dispatch);
    console.log(tree);
  };

  const confirmDelete = () => {
    const confirmation = window.confirm(
      `¿Seguro que quieres eliminar la minificha: ${minificha?.file?.apellido1} ${minificha?.file?.apellido2}, ${minificha?.file?.nombre}?`
    );
    if (confirmation)
      console.log("No se puede eliminar un dossier por el momento");
  };

  const addButton = (e) => {
    const name = e.target.name.split("-")[1];
    addToArray(name, newData[name], dispatch);
  };

  const handleDelete = (itemToDelete) => {
    removeFromArray(itemToDelete, dispatch);
  };

  useEffect(() => {
    if (state.newMinifichaCode === minifichaId) return;
    const fetchData = async () => {
      await readMinificha(minifichaId, dispatch);
    };
    fetchData();
  }, []);

  const onChange = (e, name) => {
    const key = name ? name : e.target.name;
    const value = e?.target ? e.target.value : e?.value ?? "";
    editFile(key, value, dispatch);
  };

  if (!minificha) {
    <h1>Cargando...</h1>;
  }

  return (
    <>
      <Button
          content="<<"
          onClick={() => navigate(`/minifichas/${expId}`)}
          style={{width: "fit-content", marginLeft: "10px"}}
        />
      <div className="checks-container">
        <div className="group-container">
          <div
            style={{
              rowGap: "10px",
              display: "flex",
              flexDirection: "column",
            }}>
            <Title text="Minificha" />
            <Input
              name="nombre"
              type="text"
              placeholder="Nombre"
              onChange={onChange}
              value={minificha?.file?.nombre}
              label="Nombre"
            />
            <Input
              name="apellido1"
              type="text"
              placeholder="Primer Apellido"
              onChange={onChange}
              value={minificha?.file?.apellido1}
              label="Primer Apellido"
            />
            <Input
              name="apellido2"
              type="text"
              placeholder="Segundo Apellido"
              onChange={onChange}
              value={minificha?.file?.apellido2}
              label="Segundo Apellido"
            />
            <Input
              name="fechaNacimiento"
              type="date"
              placeholder="Fecha de Nacimiento"
              onChange={onChange}
              value={minificha?.file?.fechaNacimiento}
              label="Fecha de Nacimiento"
            />
            <Select
              isSearchable={true}
              isClearable={true}
              name="provNaci"
              options={provincias}
              placeholder="Selecciona Lugar de Nacimiento..."
              onChange={(e) => onChange(e, "provNaci")}
              label="Selecciona Lugar de Nacimiento"
              value={provincias.find(
                (provincia) =>
                  provincia.value === minificha?.file?.provNacimiento
              )}
            />
            <Input
              name="fechaDefuncion"
              type="date"
              placeholder="Fecha de Defunción"
              value={minificha?.file?.fechaDefuncion}
              onChange={onChange}
              label="Fecha de Defunción"
            />
            <Select
              isSearchable={true}
              isClearable={true}
              name="provDef"
              options={provincias}
              placeholder="Selecciona Lugar de Fallecimiento..."
              onChange={(e) => onChange(e, "provDef")}
              label="Selecciona Lugar de Fallecimiento"
              value={provincias.find(
                (provincia) =>
                  provincia.value === minificha?.file?.provDefuncion
              )}
            />
            <Select
              isSearchable={true}
              isClearable={true}
              name="padre"
              options={state.personaOptions}
              placeholder="Padre..."
              onChange={(e) => onChange(e, "padre")}
              label="Padre"
              value={
                state.personaOptions?.find(
                  (data) => data.value === minificha?.file?.padre
                ) || null
              }
            />
            <Select
              isSearchable={true}
              isClearable={true}
              name="madre"
              options={state.personaOptions}
              placeholder="Madre..."
              onChange={(e) => onChange(e, "madre")}
              label="Madre"
              value={
                state.personaOptions?.find(
                  (data) => data.value === minificha?.file?.madre
                ) || null
              }
            />
            <TextArea
              id="notas"
              value={minificha?.file?.notas}
              name="notas"
              placeholder="Notas"
              onChange={onChange}
              label="Notas"
            />

            <div className="actions-buttons">
              <Button
                id="createCheck"
                onClick={save}
                content="Guardar"
              />
              <Button
                id="deleteCheck"
                onClick={confirmDelete}
                content="Borrar"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="group-container">
          <div className="actions-buttons-container">
            <div className="actions-container">
              <span className="actions-text">
                <Title text="Relaciones:" />
                <Button
                  name="add-relaciones"
                  onClick={addButton}
                  content="+"
                  buttonStyle={buttonStyle}
                />
              </span>
              {state.minificha?.file?.relaciones?.map((relation, index) => (
                <Relation
                  name={`relaciones-${index}`}
                  key={index}
                  relation={relation}
                  onDelete={handleDelete}
                  onChange={(updatedRelation) => {
                    onChange({ value: updatedRelation }, `relaciones-${index}`);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="checks-container">
        <div className="group-container">
          <span className="actions-text">
            <Title text="Direcciones:" />
            <Button
              name="add-direcciones"
              onClick={addButton}
              content="+"
              buttonStyle={buttonStyle}
            />
          </span>
          {state.minificha?.file?.direcciones?.map((direction, index) => (
            <Direction
              name={`direcciones-${index}`}
              key={index}
              direction={direction}
              onDelete={handleDelete}
              onChange={(updatedDirection) => {
                onChange({ value: updatedDirection }, `direcciones-${index}`);
              }}
            />
          ))}
        </div>
        <div className="group-container">
          <Title text="Contacto:" />
          <EditList
            title="Teléfonos"
            name="telefonos"
            placeHolder="telefono"
            onAdd={addButton}
            onDelete={handleDelete}
            onChange={onChange}
          />
          <EditList
            title="Emails"
            name="emails"
            placeHolder="Email"
            onAdd={addButton}
            onDelete={handleDelete}
            onChange={onChange}
          />
          <EditList
            title="Otros"
            name="otros"
            placeHolder="Otro"
            onAdd={addButton}
            onDelete={handleDelete}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export default Editar;
