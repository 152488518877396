import minifichaActionTypes from "../actionTypes/minifichaActionTypes";

export const minifichaReducer = (state, action) => {
  switch (action.type) {
    case minifichaActionTypes.SET_NEW_MINIFICHA_CODE: {
      return {
        ...state,
        newMinifichaCode: action.payload,
      }
    }
    case minifichaActionTypes.FETCH_FAILURE: {
      return {
        ...state,
        error: action.payload,
      }
    }
    case minifichaActionTypes.FETCH_FIND_MINIFICHAS_SUCCESS: {
      return {
        ...state,
        minifichas: action.payload.minifichas,
        personaOptions: action.payload.personaOptions
      }
    }
    case minifichaActionTypes.FETCH_MINIFICHA_SUCCESS: {
      return {
        ...state,
        minificha: action.payload,
      }
    }
    case minifichaActionTypes.ADD_TO_ARRAY_MINIFICHA: {
      const file = state.minificha?.file ? state.minificha.file :{}
      return {
        ...state,
        minificha: {
          ...state.minificha,
          file: {
            ...file,
            [action.payload.key]: file[action.payload.key]?.length > 0 ?[...state.minificha.file[action.payload.key], action.payload.value ]: [action.payload.value]
          }
        }
      }
    }
    case minifichaActionTypes.DELETE_FROM_ARRAY_MINIFICHA: {
      const updatedArray = state.minificha.file[action.payload.key].filter(
        (item, index) => index !== action.payload.index
      );
      return {
        ...state,
        minificha: {
          file: {
            ...state.minificha.file,
            [action.payload.key]: updatedArray
          }
        }
      }
    }
    case minifichaActionTypes.EDIT_FILE_MINIFICHA: {
      let name = action.payload.name
      let value = action.payload.value
      if(action.payload.name.includes('-')) {
        const [key, index] = action.payload.name.split('-')
        const newArr = [...state.minificha.file[key]]
        newArr[index] = value
        value = newArr
        name = key
      }
      const file = state.minificha?.file ?state.minificha.file :{}
      const newState = {
        ...state,
        minificha: {
          ...state.minificha,
          file: {
            ...file,
            [name]: value 
          }
        }
      }
      return newState
    }
    case minifichaActionTypes.SAVE_TREE: {
      return {
        ...state,
        tree: action.payload
      }
    }
    default: {
      console.log(`Minificha action ${action.type}`)
      break;
    }
  }
}