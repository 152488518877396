import { Dossier } from "src/lib/api";

export const generateMermaidTree1 = async (expId) => {
  const filters = {
    "header.filetype": "minificha",
    "file.expId": expId,
  };
  const dossiers = await Dossier.find(filters);

  if (dossiers.length <= 0) return;

  let mermaidCode = `%%{
  init: {
    'themeVariables': {
      'lineColor': '#FFFF'
    }
  }
}%%\nflowchart TD`;
  const marriages = new Map(); // Para almacenar nodos de matrimonio
  const persons = new Map(); // Para almacenar datos de personas
  const connectedChildren = new Set(); // Para almacenar hijos ya conectados

  // 1. Recorremos los dossiers para almacenar la información básica de cada persona.
  dossiers.forEach((dossier) => {
    const person = dossier.header.name.trim();
    const code = dossier.header.code.replace("-", "");
    const padre = dossier.file.padre?.replace("-", "") || null;
    const madre = dossier.file.madre?.replace("-", "") || null;
    const fechaNacimiento = dossier.file.fechaNacimiento
      ?.split("-")
      .reverse()
      .join("/");
    const fechaDefuncion = dossier.file.fechaDefuncion
      ?.split("-")
      .reverse()
      .join("/");
    const fechas = fechaDefuncion
      ? `\n***fa:fa-cross** ${fechaDefuncion}*`
      : "";

    // Guardamos a cada persona con su código único y sus padres
    persons.set(code, { name: person, padre, madre });
    mermaidCode += `\n${code}[" ${
      fechaNacimiento ? ` *${fechaNacimiento}*\n` : ""
    }**${person}**${fechas}"]\nclick ${code} "/editficha/${
      dossier.file.expId
    }/${dossier.header.code}"`;
  });

  // 2. Procesamos los matrimonios primero.
  persons.forEach((person, code) => {
    const { padre, madre } = person;

    if (padre && madre) {
      const marriageKey = `M${[padre, madre].sort().join("")}`; // Usamos una clave para evitar duplicados
      if (!marriages.has(marriageKey)) {
        const marriageNode = `${marriageKey}{" "}`;
        marriages.set(marriageKey, marriageNode);
        mermaidCode += `\n\tstyle ${padre} fill:#5aa8ff,stroke:#000000,stroke-width:2px;`;
        mermaidCode += `\n\tstyle ${madre} fill:#ff94dd,stroke:#000000,stroke-width:2px;`; // Rosa para madres

        // Conectar los padres al nodo de matrimonio.
        mermaidCode += `\n${padre} --- ${marriageNode}`;
        mermaidCode += `\n${madre} --- ${marriageNode}`;
      }
    }
  });

  // 3. Recorremos los matrimonios y agregamos a los hijos.
  persons.forEach((person, code) => {
    const { padre, madre } = person;

    if (padre && madre) {
      const marriageKey = `M${[padre, madre].sort().join("")}`;
      const marriageNode = marriages.get(marriageKey);

      // Solo añadimos la conexión si el hijo no ha sido conectado previamente
      if (marriageNode && !connectedChildren.has(`${marriageKey}${code}`)) {
        mermaidCode += `\n${marriageNode} --- ${code}`;
        connectedChildren.add(`${marriageKey}${code}`); // Marcamos al hijo como conectado
      }
    }
  });

  mermaidCode += "\nlinkStyle default stroke:#FFFFFF, stroke-width:3px";
  return mermaidCode;
};

function findUnions(dossiers) {
  const unions = [];

  // Procesar cada dossier
  dossiers.forEach((dossier) => {
    // Procesar matrimonios de padre y madre en el dossier
    if (dossier.file.padre && dossier.file.madre) {
      let existingUnion = unions.find(
        (union) => union.has(dossier.file.padre) || union.has(dossier.file.madre)
      );
      if (existingUnion) {
        existingUnion.add(dossier.file.padre);
        existingUnion.add(dossier.file.madre);
      } else {
        unions.push(new Set([dossier.file.padre, dossier.file.madre]));
      }
    }

    // Procesar relaciones de tipo 'conyuge' en dossier.file.relaciones
    if (dossier.file && Array.isArray(dossier.file.relaciones)) {
      dossier.file.relaciones.forEach((relacion) => {
        if (relacion.relation === "conyuge") {
          const conyuge1 = dossier.header.code;
          const conyuge2 = relacion.person.value;
          let existingUnion = unions.find(
            (union) => union.has(conyuge1) || union.has(conyuge2)
          );
          if (existingUnion) {
            existingUnion.add(conyuge1);
            existingUnion.add(conyuge2);
          } else {
            unions.push(new Set([conyuge1, conyuge2]));
          }
        }
      });
    }
  });

  // Unificar uniones conectadas entre sí
  let merged = true;
  while (merged) {
    merged = false;
    for (let i = 0; i < unions.length; i++) {
      for (let j = i + 1; j < unions.length; j++) {
        if ([...unions[i]].some((person) => unions[j].has(person))) {
          unions[i] = new Set([...unions[i], ...unions[j]]);
          unions.splice(j, 1);
          merged = true;
          break;
        }
      }
      if (merged) break;
    }
  }

  return unions.map((union) => [...union]);
}
function generateMarriageKeys(union) {
  const keys = [];

  // Genera todas las combinaciones de dos personas en la unión
  for (let i = 0; i < union.length; i++) {
    for (let j = i + 1; j < union.length; j++) {
      const padre = union[i];
      const madre = union[j];

      // Genera la clave en el formato dado, con el sort para asegurar el orden
      const marriageKey = `M${[padre, madre].sort().join("")}`;
      keys.push(marriageKey);
    }
  }

  return keys;
}

export const generateMermaidTree = async (expId) => {
  const filters = {
    "header.filetype": "minificha",
    "file.expId": expId,
  };
  const dossiers = await Dossier.find(filters);

  if (dossiers.length <= 0) return;

  let mermaidCode = `%%{
  init: {
    'themeVariables': {
      'lineColor': '#FFFF'
    }
  }
}%%\nflowchart TD`;
  const marriages = new Map(); // Para almacenar nodos de matrimonio
  const persons = new Map(); // Para almacenar datos de personas
  const connectedChildren = new Set(); // Para almacenar hijos ya conectados

  const unions = findUnions(dossiers);
  console.log(unions);

  // 1. Recorremos los dossiers para almacenar la información básica de cada persona.
  dossiers.forEach((dossier) => {
    const person = dossier.header.name.trim();
    const code = dossier.header.code.replace("-", "");
    const padre = dossier.file.padre?.replace("-", "") || null;
    const madre = dossier.file.madre?.replace("-", "") || null;
    const fechaNacimiento = dossier.file.fechaNacimiento
      ?.split("-")
      .reverse()
      .join("/");
    const fechaDefuncion = dossier.file.fechaDefuncion
      ?.split("-")
      .reverse()
      .join("/");
    const fechas = fechaDefuncion ? `\n***†** ${fechaDefuncion}*` : "";

    // Guardamos a cada persona con su código único y sus padres
    const node = `${code}[" ${
      fechaNacimiento ? ` *${fechaNacimiento}*\n` : ""
    }**${person}**${fechas}"]`;
    const clickNode = `click ${code} "/editficha/${dossier.file.expId}/${dossier.header.code}"`;
    persons.set(code, {
      name: person,
      padre,
      madre,
      node,
      clickNode,
      created: false,
      relaciones: dossier.file.relaciones,
    });
  });

  // 2. Procesamos los matrimonios primero.
  persons.forEach((person, code) => {
    const { padre, madre } = person;
    const conyuges =
      person.relaciones?.filter((r) => r.relation === "conyuge") || [];
    if (padre && madre) {
      const marriageKey = `M${[padre, madre].sort().join("")}`; // Usamos una clave para evitar duplicados
      if (!marriages.has(marriageKey)) {
        const marriageNode = `${marriageKey}{" "}`;
        marriages.set(marriageKey, {
          marriageNode,
          hombre: padre,
          mujer: madre,
        });
      }
    }

    if (conyuges?.length > 0) {
      conyuges.forEach((conyuge) => {
        const conyugeCode = conyuge.person.value.replace("-", "");
        const marriageKey = `M${[code, conyugeCode].sort().join("")}`; // Usamos una clave para evitar duplicados
        if (!marriages.has(marriageKey)) {
          const marriageNode = `${marriageKey}{" "}`;
          marriages.set(marriageKey, {
            marriageNode,
            hombre: code,
            mujer: conyugeCode,
          });
        }
      });
    }
  });

  unions.forEach((union) => {
    const key = union.join("").replace(/-/g, "");
    mermaidCode += `\n\tsubgraph ${key} [" "]`;
    const marriagesKey = generateMarriageKeys(union);
    const correctMarriages = marriagesKey.filter((m) =>
      marriages.get(m.replace(/-/g, ""))
    );

    correctMarriages.forEach((marriage, index) => {
      const marriageData = marriages.get(marriage.replace(/-/g, ""));
      if (!marriageData) {
        return;
      }
      const padre = marriageData.hombre;
      const madre = marriageData.mujer;
      mermaidCode += `\n\tstyle ${padre} fill:#5aa8ff,stroke:#000000,stroke-width:2px;`;
      mermaidCode += `\n\tstyle ${madre} fill:#ff94dd,stroke:#000000,stroke-width:2px;`; // Rosa para madres
      // Conectar los padres al nodo de matrimonio.
      mermaidCode += `\n\t${persons.get(padre).node} --- ${
        marriageData.marriageNode
      }`;
      mermaidCode += `\n\t${persons.get(madre).node} --- ${
        marriageData.marriageNode
      }`;
      mermaidCode += `\n${persons.get(padre).clickNode}`;
      mermaidCode += `\n${persons.get(madre).clickNode}`;
      persons.set(padre, { ...persons.get(padre), created: true });
      persons.set(madre, { ...persons.get(madre), created: true });
    });

    mermaidCode += `\n\tend`;
    mermaidCode += `\nstyle ${key} fill:#ffffde08, stroke:#33a5aa`;
  });

  // 3. Recorremos los matrimonios y agregamos a los hijos.
  persons.forEach((person, code) => {
    const { padre, madre } = person;

    if (padre && madre) {
      const marriageKey = `M${[padre, madre].sort().join("")}`;
      const marriageData = marriages.get(marriageKey);

      // Solo añadimos la conexión si el hijo no ha sido conectado previamente
      if (
        marriageData.marriageNode &&
        !connectedChildren.has(`${marriageKey}${code}`)
      ) {
        mermaidCode += `\n\t${marriageData.marriageNode} --- ${person.node}`;
        mermaidCode += `\n${person.clickNode}`;
        connectedChildren.add(`${marriageKey}${code}`); // Marcamos al hijo como conectado
        persons.set(code, { ...person, created: true });
      }
    }
  });

  persons.forEach((person) => {
    if (person.created) return;
    mermaidCode += `\n${person.node}`;
    mermaidCode += `\n${person.clickNode}`;
  });
  mermaidCode += "\nlinkStyle default stroke:#FFFFFF, stroke-width:3px";
  return mermaidCode;
};
