export const EXPCOLUMNS = [
  {
    Header: 'Nombre',
    accessor: 'name',
    type: 'link',
  },
  {
    Header: 'Código',
    accessor: 'code',
  },
];